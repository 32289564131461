import React from 'react';
import { Row, Col, Form, Button, Alert, InputGroup, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

import '../assets/css/login.css'
import '../assets/css/nologin.css'

import request from '../libs/request';

import Line from '../component/line';
import Site from '../component/site';
import Chat from '../component/chat';
import Intro from '../component/intro';

import GA from '../component/ga'


import Layout from '../component/layout/guest';

const cookies = new Cookies();

const CLOSE_REGISTER = (process.env.REACT_APP_CLOSE_REGISTER && process.env.REACT_APP_CLOSE_REGISTER === 'true') || false

class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      web_title: null,
      web_description: null,
      loading: false,
      username: '',
      success: false,
      system: null,
      intro: null,
      redirectTarget: null,
    }

    this.submitForm = this.submitForm.bind(this);
    this.changeValue = this.changeValue.bind(this);
  }


  componentDidMount() {
    if (cookies.get(process.env.REACT_APP_COOKIE_MEMBER)) cookies.remove(process.env.REACT_APP_COOKIE_MEMBER);


    this.setState({ loading: true, success: false }, () => {

      request.get('page/forgetpassword').then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {
              let system = result.data.system;

              const have_forgetPassword = (system && system.configuration && system.configuration.feature && system.configuration.feature.forgetPassword);
              if (have_forgetPassword) {
                let data = {
                  system,
                  intro: result.data.intro,
                }

                //if (system.configuration.message && system.configuration.message.forgetPassword) data.intro = system.configuration.message.forgetPassword;



                let web_title = system.name, web_description = null
                if (system.configuration.seo && system.configuration.seo.forgetPassword) {
                  web_title = system.configuration.seo.forgetPassword.title
                  web_description = system.configuration.seo.forgetPassword.description
                }

                //document.title = web_title
                data.web_title = web_title
                if (web_description) data.web_description = web_description



                this.setState(data, () => {
                  cookies.set(process.env.REACT_APP_COOKIE_SYSTEM, {
                    id: system.id,
                    code: system.code,
                    name: system.name,
                    domain: system.domain || 'ufabet',
                    maintenance: system.configuration.maintenance,
                  })
                })
              }
              else {

                this.setState({ success: true })
              }
            }
            else alert(result.message);

          });

        }
        else {
          console.log('Cannot register');
        }
      })

    })


  }

  changeValue(e) {
    let id = e.currentTarget.id.split('_').pop();
    let data = { ...this.state };
    data[id] = e.currentTarget.value || '';
    if (data[id]) {
      data[id] = data[id].replace(/\s+/g, '');
      if (id === 'username') data[id] = data[id].toLowerCase();
    }

    this.setState(data);
  }


  submitForm(e) {
    e.preventDefault();

    this.setState({ loading: true, success: false }, () => {

      request.post('member/forgetpassword', this.state).then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result && result.success) {
              alert(result.data.message)

              this.setState({ success: true })
            }
            else {
              alert(result.message)
            }

          });

        }
        else {
          this.setState({ loading: false });
          console.log('Cannot login');
          alert('ไม่สามารถเข้าสู่ระบบได้ขณะนี้')
        }
      })


    })
  }


  openJoker() {
    let url = 'http://www.joker123.net/';
    let win = window.open(url);
    win.addEventListener('locationchange', function (e) {
      console.log('Navigation occuring', e.currentTarget.location);
    });
  }

  render() {
    const { loading, system, username, intro, redirectTarget, web_title, web_description } = this.state;

    let logo = require('../assets/images/logo.jpg');
    if (process.env.REACT_APP_LOGO) logo = process.env.REACT_APP_LOGO;
    else if (system && system.logo) logo = system.logo;


    //const is_joker = (system && system.domain === 'joker');

    return (
      <>

        <Layout web_title={web_title} web_description={web_description}>
          {system &&
            <>

              <Row className="justify-content-md-center ">
                <Col sm={4}  >
                  <Card className="login-form">
                    <Card.Body>
                      <div className="text-center">
                        <img src={logo} alt={web_title} className="img-fluid mb-2" style={{ maxHeight: 180 }} />
                      </div>

                      {system.configuration.maintenance ? (

                        <Alert variant="warning">
                          <div dangerouslySetInnerHTML={{ __html: system.configuration.message ? system.configuration.message.maintenance || 'ปิดปรับปรุงระบบ' : 'ปิดปรับปรุงระบบ' }}></div>
                        </Alert>
                      ) : (
                        <>
                          <div className="text-center">
                            <h2>ลืมรหัสผ่าน</h2>
                          </div>

                          <Intro intro={intro} />

                          <Form onSubmit={this.submitForm} className="mt-2">
                            <Form.Group controlId="forgetpwd_username">
                              <Form.Label>ชื่อผู้ใช้ระบบ หรือ เบอร์โทรศัพท์มือถือ</Form.Label>
                              <InputGroup size="lg">
                                <InputGroup.Prepend>
                                  <InputGroup.Text className="input-group-custom">
                                    <FontAwesomeIcon icon={faInfoCircle} className="gold" />
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control className="input-with-icon gold" type="text" placeholder="" value={username} required onChange={this.changeValue} />
                              </InputGroup>
                            </Form.Group>


                            {loading ? (
                              <div className="text-center">
                                <FontAwesomeIcon icon={faSpinner} size="3x" spin /> <br />กรุณารอสักครู่...
                              </div>
                            ) : (
                              <Button size="lg" variant="primary" type="submit" className="btn-gold" block>
                                แจ้งลืมรหัสผ่าน
                              </Button>
                            )}

                          </Form>

                          <br />



                          <div className="row text-center">
                            {!CLOSE_REGISTER &&
                              <div className="col-sm-6 text-center mt-2" >
                                <Button block variant="primary" onClick={() => { this.setState({ redirectTarget: '/register' }) }}>สมัครสมาชิก</Button>
                              </div>
                            }

                            <div className="col-sm-6 text-center mt-2">
                              <Button block variant="success" onClick={() => { this.setState({ redirectTarget: '/' }) }}>คลิกที่นี่เพื่อเข้าสู่ระบบ</Button>
                            </div>

                          </div>

                        </>
                      )}

                    </Card.Body>
                  </Card>
                </Col>
              </Row>


              {system.configuration.line && <Line id={system.configuration.line} />}
            </>
          }

          <Site />
          <Chat />

        </Layout>

        <GA />

        {this.state.success && <Redirect to='/' />}
        {redirectTarget && <Redirect to={redirectTarget} />}

      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(ForgetPassword);