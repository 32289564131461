import React from 'react';
import { Row, Col, Form, Button, Alert, InputGroup, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faUser, faLock, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';


import '../assets/css/login.css'
import '../assets/css/nologin.css'

import request from '../libs/request';
import utility from '../libs/utility';

import Layout from '../component/layout/guest';
import GA from '../component/ga'


import Line from '../component/line';
import Intro from '../component/intro';

const cookies = new Cookies();

const CLOSE_REGISTER = (process.env.REACT_APP_CLOSE_REGISTER && process.env.REACT_APP_CLOSE_REGISTER === 'true') || false

class Login extends React.Component {
  constructor(props) {
    super(props);


    let reference = '';
    let refer = this.props.location.search.replace('?', '');
    if (this.props.location.search) {
      if (refer.substring(0, 1).toLowerCase() === '@') {
        if (cookies.get(process.env.REACT_APP_COOKIE_DOWNLINE)) cookies.remove(process.env.REACT_APP_COOKIE_DOWNLINE);
        cookies.set(process.env.REACT_APP_COOKIE_DOWNLINE, { code: refer });
      }
      else reference = refer;
    }


    this.state = {
      web_title: null,
      web_description: null,
      loading: false,
      username: '',
      password: '',
      phone: '',
      success: false,
      intro: null,
      reference: reference,
      system: null,
      login_format: 'username',
      redirectTarget: null,
      line_userid: null,
      line_liffid: null,
      pathname: '/',
    }

    this.submitForm = this.submitForm.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.login = this.login.bind(this);
  }


  componentDidMount() {
    if (cookies.get(process.env.REACT_APP_COOKIE_MEMBER)) cookies.remove(process.env.REACT_APP_COOKIE_MEMBER);

    //console.log('path', utility.getQueryString(this.props, 'path'))
    let refer_path = utility.getQueryString(this.props, 'path')
    if (refer_path === 'null') refer_path = null;

    this.setState({ loading: true, success: false, pathname: refer_path || '/' }, () => {

      request.get('page/login').then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {
              const system = result.data.system;

              let data = { system, login_format: system.configuration.login, intro: result.data.intro };

              //console.log('result.data.intro', result.data.intro)

              let web_title = system.name, web_description = null
              if (system.configuration.seo && system.configuration.seo.login) {
                web_title = system.configuration.seo.login.title
                web_description = system.configuration.seo.login.description
              }

              data.web_title = web_title
              if (web_description) data.web_description = web_description


              //console.log('seo', system.configuration.seo)

              //if (system.configuration.message && system.configuration.message.login) data.intro = system.configuration.message.login;
              //console.log('system.configuration', system.configuration.line)
              this.setState(data, async () => {
                cookies.set(process.env.REACT_APP_COOKIE_SYSTEM, {
                  id: system.id,
                  code: system.code,
                  name: system.name,
                  domain: system.domain || 'ufabet',
                  maintenance: system.configuration.maintenance,
                  login_format: system.configuration.login,
                })

                if (!system.configuration.maintenance && system.configuration.liff && system.configuration.liff.use && system.configuration.liff.id) {

                  const LIFF_ID = system.configuration.liff.id

                  this.setState({ line_liffid: LIFF_ID })

                }
                else {
                  //console.log('Line LIFF ID not found')
                }

              })
            }
            else {
              alert(result.message);
            }

          });

        }
        else {
          console.log('Cannot register');
        }
      })

    })
  }


  changeValue(e) {
    let id = e.currentTarget.id.split('_').pop();
    let data = { ...this.state };
    data[id] = e.currentTarget.value || '';
    if (data[id]) {
      data[id] = data[id].replace(/\s+/g, '');
      if (id === 'username') data[id] = data[id].toLowerCase();
    }

    this.setState(data);
  }


  submitForm(e) {
    e.preventDefault();

    let where = {
      username: this.state.username,
      password: this.state.password,
      login_format: this.state.login_format,
    }

    if (this.state.line_userid) where.line_userid = this.state.line_userid

    this.login(where)
  }


  login(data) {

    this.setState({ loading: true, success: false }, () => {

      request.post('member/login', data).then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {
              let token = result.data.token;
              let username = result.data.username;

              cookies.set(process.env.REACT_APP_COOKIE_MEMBER, {
                token,
                username,
                //system: { code: this.state.system.code, name: this.state.system.name, domain: this.state.system.domain || 'ufabet' },
              });
              //}, { maxAge: 1 * 60 * 60 });

              this.setState({ success: true });
            }
            else {
              if (result.message === 'line_notfound') {
                //console.log(result.message)
                if (data.line_userid) {
                  this.setState({ line_userid: data.line_userid });
                }

              }
              else
                alert(result.message);
            }

          });

        }
        else {
          this.setState({ loading: false });
          console.log('Cannot login');
          alert('ไม่สามารถเข้าสู่ระบบได้ขณะนี้')
        }
      })


    })
  }


  openJoker() {
    let url = 'http://www.joker123.net/';
    let win = window.open(url);
    win.addEventListener('locationchange', function (e) {
      console.log('Navigation occuring', e.currentTarget.location);
    });
  }

  render() {
    const { loading, system, username, password, intro, login_format, redirectTarget, web_title, web_description, line_liffid, pathname } = this.state;

    let logo = require('../assets/images/logo.jpg');
    if (process.env.REACT_APP_LOGO) logo = process.env.REACT_APP_LOGO;
    else if (system && system.logo) logo = system.logo;

    const have_forgetPassword = (system && system.configuration && system.configuration.feature && system.configuration.feature.forgetPassword);


    return (
      <>
        <Layout loading={loading} web_title={web_title} web_description={web_description} line_liffid={line_liffid} submitLogin={this.login}>
          {system &&
            <>

              <Row className="justify-content-md-center " style={{ padding: 0 }}>
                <Col sm={4} >
                  <Card className="login-form">
                    <Card.Body style={{ padding: 0 }}>
                      <div className="text-center">
                        <img src={logo} alt={web_title} className="img-fluid mb-2" style={{ maxHeight: 180 }} />
                      </div>


                      {system.configuration.maintenance ? (

                        <Alert variant="warning">
                          <div dangerouslySetInnerHTML={{ __html: system.configuration.message ? system.configuration.message.maintenance || 'ปิดปรับปรุงระบบ' : 'ปิดปรับปรุงระบบ' }}></div>
                        </Alert>
                      ) : (
                        <>

                          <Intro intro={intro} />


                          <Form onSubmit={this.submitForm} className="mt-2">
                            <Form.Group controlId="login_username">
                              <Form.Label> {(login_format === 'phone') ? 'เบอร์โทรศัพท์มือถือ' : 'ชื่อผู้ใช้ระบบ'}</Form.Label>
                              <InputGroup size="lg">
                                <InputGroup.Prepend>
                                  <InputGroup.Text className="input-group-custom">
                                    <FontAwesomeIcon icon={(login_format === 'phone') ? faMobileAlt : faUser} className="gold" />
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                {(login_format === 'phone') ? (
                                  <Form.Control className="input-with-icon gold" type="text" placeholder="กรุณากรอก เบอร์โทรศัพท์มือถือ 10 หลัก" pattern="^[0-9]+$" title="กรุณากรอก เบอร์โทรศัพท์มือถือ 10 หลัก" minLength={10} maxLength={10} required value={username} onChange={this.changeValue} />
                                ) : (
                                  <Form.Control className="input-with-icon gold" type="text" placeholder="" value={username} required onChange={this.changeValue} />
                                )}

                              </InputGroup>
                            </Form.Group>

                            <Form.Group controlId="login_password">
                              <Form.Label>รหัสผ่าน</Form.Label>
                              <InputGroup size="lg">
                                <InputGroup.Prepend>
                                  <InputGroup.Text className="input-group-custom">
                                    <FontAwesomeIcon icon={faLock} />
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control className="input-with-icon" type="password" placeholder="" required value={password} onChange={this.changeValue} />
                              </InputGroup>
                            </Form.Group>


                            {loading ? (
                              <div className="text-center">
                                <FontAwesomeIcon icon={faSpinner} size="3x" spin /> <br />
                                กรุณารอสักครู่...
                              </div>
                            ) : (
                              <Button size="lg" variant="primary" type="submit" className="btn-gold" block>
                                เข้าสู่ระบบ
                              </Button>
                            )}

                          </Form>

                          <br />
                          <hr className="gold" />

                          <div className="row text-center">
                            {!CLOSE_REGISTER &&
                              <div className="col-sm-6 text-center mt-4" >
                                <Button block variant="primary" size="lg" onClick={() => { this.setState({ redirectTarget: '/register' }) }}>สมัครสมาชิก</Button>
                              </div>
                            }

                            {have_forgetPassword &&
                              <div className="col-sm-6 text-center mt-4">
                                <Button block variant="info" size="lg" onClick={() => { this.setState({ redirectTarget: '/forgetpassword' }) }}>ลืมรหัสผ่าน</Button>
                              </div>
                            }
                          </div>


                        </>
                      )}

                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              {system.configuration.line && <Line id={system.configuration.line} />}
            </>
          }
        </Layout>
        {this.state.success && <Redirect to={pathname} />}
        {redirectTarget && <Redirect to={redirectTarget} />}

        <GA />
      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(Login);