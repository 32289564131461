import React from 'react';
import { Row, Col, Form, Button, Alert, Modal, Card, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faMobileAlt, faMoneyCheck, faSignature } from '@fortawesome/free-solid-svg-icons'
import { Redirect } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import _ from 'lodash';

import '../assets/css/register.css';
import '../assets/css/nologin.css'

import request from '../libs/request';

import Line from '../component/line';
import Intro from '../component/intro'

import Cookies from 'universal-cookie';

import Layout from '../component/layout/guest';
import GA from '../component/ga'


const cookies = new Cookies();

const TOTAL_RETRY_CHECK_TW = 1
const DELAY_CHECK_TW = [5, 30, 30, 30, 30, 30]

const CLOSE_REGISTER = (process.env.REACT_APP_CLOSE_REGISTER && process.env.REACT_APP_CLOSE_REGISTER === 'true') || false


class Register extends React.Component {
  constructor(props) {
    super(props);

    let reference = '';
    let refer = this.props.location.search.replace('?', '');
    if (this.props.location.search) {
      if (refer.substring(0, 1).toLowerCase() === '@') {
        if (cookies.get(process.env.REACT_APP_COOKIE_DOWNLINE)) cookies.remove(process.env.REACT_APP_COOKIE_DOWNLINE);
        cookies.set(process.env.REACT_APP_COOKIE_DOWNLINE, { code: refer.replace('@', '') });
      }
      else reference = refer;
    }


    this.state = {
      web_title: null,
      web_description: null,
      loading: false,
      login: false,
      phone: '',
      firstname: '',
      lastname: '',
      banks: [],
      bank: 'truewallet',
      acceptpromotion: 'yes',
      channel: '',
      banknumber: '',
      lineid: '',
      reference: reference,
      intro: null,
      noAcceptPromotion: '',
      showModalSuccess: false,
      finish: false,
      channels: [],
      promotion: {},
      verifyCaptcha: false,
      isTrueWallet: false,
      isBank: false,
      redirectTarget: null,
      system: null,
      showFillName: false,
      allowFillName: false,
      useTrueWalletID: false,
      useTrueWallet: false,
      useBank: false,
      tw: { phone: '', id: '' },
      retryCheckTruewallet: 0,
      delayCheckTruewallet: 0,
      isDelayCheckTruewallet: false,
      login_success: false,
      line_userid: null,
      line_liffid: null,
      formdata: {},
    }

    this.submitForm = this.submitForm.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.selectPromotion = this.selectPromotion.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.selectUseTruewalletID = this.selectUseTruewalletID.bind(this)
    this.selectUseTruewallet = this.selectUseTruewallet.bind(this)
    this.selectUseBank = this.selectUseBank.bind(this)
    this.checkTruewalletInfo = this.checkTruewalletInfo.bind(this)
    this.allowCheckTruewalletInfo = this.allowCheckTruewalletInfo.bind(this)
    this.login = this.login.bind(this)
    this.countdownCheckTW = this.countdownCheckTW.bind(this)
  }

  componentDidMount() {

    document.title = 'สมัคร UFABET เว็บตรง ฝากถอน วอเลท ออโต้ ไม่มีขั้นต่ำ'


    try {
      // loadReCaptcha(process.env.REACT_APP_RECAPTCHA);
    } catch (error) {
      console.error(error)
    }


    if (cookies.get(process.env.REACT_APP_COOKIE_MEMBER)) cookies.remove(process.env.REACT_APP_COOKIE_MEMBER);

    request.get('page/register').then(response => {

      if (response.data && response.data.status_code === 200) {

        let result = response.data;
        if (result.success) {

          const system = result.data.system;

          let channels = system.configuration.registerChannel;


          const isTrueWallet = (_.findIndex(system.banks, { type: 'truewallet' }) !== -1);
          const isBank = (_.findIndex(system.banks, { type: 'bank' }) !== -1);

          let bank_default = 'scb';

          let data = {
            intro: result.data.intro,
            noAcceptPromotion: system.configuration.message ? system.configuration.message.noAcceptPromotion : '',
            channels: channels,
            channel: '',
            banks: _.filter(system.banks, { type: 'bank' }),
            bank: bank_default,
            isTrueWallet,
            isBank,
            system,
            formdata: { bank: bank_default },
          }

          if (!isBank) data.useTrueWallet = true



          let web_title = system.name, web_description = null
          if (system.configuration.seo && system.configuration.seo.register) {
            web_title = system.configuration.seo.register.title
            web_description = system.configuration.seo.register.description
          }

          data.web_title = web_title
          if (web_description) data.web_description = web_description

          this.setState(data, async () => {

            cookies.set(process.env.REACT_APP_COOKIE_SYSTEM, {
              id: system.id,
              code: system.code,
              name: system.name,
              domain: system.domain || 'ufabet',
              maintenance: system.configuration.maintenance,
            })



            if (!system.configuration.maintenance && system.configuration.liff && system.configuration.liff.use && system.configuration.liff.id) {

              const LIFF_ID = system.configuration.liff.id

              this.setState({ line_liffid: LIFF_ID })

            }
            else {
              //console.log('Line LIFF ID not found')
            }
          })
        }
        else {
          console.log(result.message);
          if (cookies.get(process.env.REACT_APP_COOKIE_DOWNLINE)) cookies.remove(process.env.REACT_APP_COOKIE_DOWNLINE);
          this.setState({ finish: true })
        }

      }
      else {
      }

    })
      .catch(console.error)
  }

  changeValue(e) {
    let tmp = e.currentTarget.id.split('_')
    tmp.shift()
    const id = tmp.join('_')// e.currentTarget.id.split('_').pop();
    let data = { ...this.state.formdata };
    let value = e.currentTarget.value || '';

    switch (id) {
      case 'phone':
        if (value !== this.state.tw.phone) {
          data.showFillName = false
          data.allowFillName = false
          data.tw = { phone: '', id: '' }
        }
        break;

      case 'twid':
        if (value !== this.state.tw.id) {
          data.showFillName = false
          data.allowFillName = false
          data.tw = { phone: '', id: '' }
        }
        break;
      default: break;
    }


    if (value) {
      value = value.replace(' ', '');
      value = value.replace('\r', '');
      value = value.replace('\n', '');
      value = value.replace('\t', '');
    }

    data[id] = value;
    this.setState({ formdata: data });
  }

  allowCheckTruewalletInfo() {
    const { formdata, useBank, useTrueWallet } = this.state;

    let allow = true

    if (!useBank && !useTrueWallet) return false;


    if (formdata?.phone && formdata?.phone.length === 10) {

      if (useBank) {
        if (!formdata?.bank_number) {
          return false;
        }
      }

      if (useTrueWallet) {

      }
    }

    return allow
  }

  allowCheckTruewalletInfo2() {
    const { phone, banknumber, useTrueWalletID, tw } = this.state

    let allow = true

    allow = (phone.length === 10)

    if (allow) allow = (!useTrueWalletID || (useTrueWalletID && banknumber.length > 0))
    if (allow) allow = (phone !== tw.phone)
    if (allow) allow = (!useTrueWalletID || (useTrueWalletID && banknumber !== tw.id))

    return allow
  }


  checkTruewalletInfo() {
    const { retryCheckTruewallet, useBank, useTrueWallet } = this.state

    if (!useBank && !useTrueWallet) {
      alert('เลือก True Wallet หรือ บัญชีธนาคาร อย่างน้อย 1 อย่าง');
      return;
    }


    let data = {
      tw: { phone: '', id: '' },
      showFillName: false,
      allowFillName: false,
      //retryCheckTruewallet: 0,
      loading: true,
      delayCheckTruewallet: DELAY_CHECK_TW[retryCheckTruewallet],
    }
    this.setState(data, async () => {

      try {

        let { formdata } = this.state

        let this_data = {
          truewallet_phone: formdata.phone,
        }

        if (this.state.useTrueWalletID) this_data.truewallet_id = formdata.twid

        formdata.useBank = useBank
        formdata.useTrueWallet = useTrueWallet

        //console.log(formdata);
        // this.setState({ loading: false }); return;

        const response = await request.post('member/register/check', formdata)
        const result = response.data

        if (result && result.success) {
          console.log('Check', result.data)

          formdata.firstname = result.data.firstname
          formdata.lastname = result.data.lastname


          this.setState({
            loading: false,
            firstname: result.data.firstname,
            lastname: result.data.lastname,
            tw: { phone: this.state.phone, id: this.state.banknumber || '' },
            showFillName: true,
            allowFillName: (result.data.firstname === ''),
            formdata,
          })
        }
        else {
          if (result.message.indexOf(':') !== -1) {

            const [resp_action, resp_message] = result.message.split(':')

            switch (resp_action) {
              case 'exists':
                this.setState({ loading: false })
                alert(resp_message)
                break;

              case 'retry':

                if (this.state.retryCheckTruewallet < TOTAL_RETRY_CHECK_TW) {
                  this.setState({ retryCheckTruewallet: this.state.retryCheckTruewallet + 1 }, () => {
                    this.countdownCheckTW();
                  })

                }
                else {
                  this.setState({ loading: false, showFillName: true, allowFillName: true, tw: { phone: this.state.phone, id: this.state.banknumber || '' } })
                }


                break;

              default:
                this.setState({ loading: false })
                alert(resp_message)
                break;
            }


          } else {
            alert(result.message)
          }


        }

      } catch (error) {
        console.error(error)
      }

    })
  }


  countdownCheckTW() {

    const { delayCheckTruewallet } = this.state

    //console.log('delayCheckTruewallet', this.state.retryCheckTruewallet, delayCheckTruewallet)

    const is_finish = (delayCheckTruewallet <= 0)


    this.setState({ isDelayCheckTruewallet: !is_finish }, () => {

      if (is_finish) {
        this.checkTruewalletInfo()
      }
      else {
        setTimeout(() => {

          this.setState({ delayCheckTruewallet: delayCheckTruewallet - 1 }, () => {
            this.countdownCheckTW()
          })

        }, 1000)
      }

    })

  }


  login(data) {


    this.setState({ loading: true, success: false }, () => {

      request.post('member/login', data).then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {
              let token = result.data.token;
              let username = result.data.username;

              cookies.set(process.env.REACT_APP_COOKIE_MEMBER, {
                token,
                username,
                //system: { code: this.state.system.code, name: this.state.system.name, domain: this.state.system.domain || 'ufabet' },
              });
              //}, { maxAge: 1 * 60 * 60 });

              this.setState({ login_success: true });
            }
            else {
              if (result.message === 'line_notfound') {
                //console.log(result.message)
              }
              else
                alert(result.message);
            }

          });

        }
        else {
          this.setState({ loading: false });
          console.log('Cannot login');
          alert('ไม่สามารถเข้าสู่ระบบได้ขณะนี้')
        }
      })


    })
  }


  submitForm(e) {
    e.preventDefault();

    if (!CLOSE_REGISTER) {

      this.setState({ loading: true }, () => {

        request.post('member/register', this.state).then(response => {
          if (response.data && response.data.status_code === 200) {

            let result = response.data;

            this.setState({ loading: false }, () => {

              if (result.success) {
                this.setState({ showModalSuccess: true }, () => {

                })
              }
              else alert(result.message);

            });

          }
          else {
            this.setState({ loading: false }, () => alert('ไม่สามารถสมัครสมาชิกได้ขณะนี้'));
          }
        }).catch(error => {
          console.error(error);
          this.setState({ loading: false }, () => alert('ไม่สามารถสมัครสมาชิกได้ขณะนี้'));
        })

      })

    }
  }


  hideModal() {
    this.setState({ showModalSuccess: false, finish: true }, () => {
    });
  }

  selectPromotion(e) {
    if (e.currentTarget.value) {
      let channel = _.find(this.state.channels, { code: e.currentTarget.value });
      if (channel) {
        let data = { channel: channel.code };
        if (channel.promotion && channel.promotion.id) data.promotion = channel.promotion;
        this.setState(data);
      }
    }
    else this.setState({ promotion: {}, channel: '' })
  }

  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!  
    //console.log(recaptchaToken, "<= your recaptcha token")
    this.setState({ verifyCaptcha: true })
  }

  selectUseTruewallet() {
    this.setState({ useTrueWallet: !this.state.useTrueWallet })
  }

  selectUseTruewalletID() {
    //this.state.useTrueWalletID = !this.state.useTrueWalletID
    const { useTrueWalletID } = this.state

    let data = { useTrueWalletID: !useTrueWalletID }

    if (data.useTrueWalletID) {
      data.showFillName = false
      data.tw = { phone: '', id: '' }
      data.firstname = ''
      data.lastname = ''
    }
    else {
      data.banknumber = ''
    }

    this.setState(data)
  }

  selectUseBank() {
    this.setState({ useBank: !this.state.useBank })
  }

  render() {
    const { web_title, web_description, loading, intro, showModalSuccess, channels, promotion, system, redirectTarget, showFillName, allowFillName, line_liffid } = this.state;

    const { isDelayCheckTruewallet, delayCheckTruewallet, useTrueWallet, useBank, isBank, banks, formdata } = this.state

    let logo = require('../assets/images/logo.jpg');
    if (process.env.REACT_APP_LOGO) logo = process.env.REACT_APP_LOGO;

    //const have_forgetPassword = (system && system.configuration && system.configuration.feature && system.configuration.feature.forgetPassword);


    return (
      <>

        <Layout loading={loading} web_title={web_title} web_description={web_description} line_liffid={line_liffid} submitLogin={this.login}>

          {system &&

            <>
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA}
                language="th"
                useRecaptchaNet={true}
                useEnterprise={false}
                scriptProps={{
                  async: false, // optional, default to false,
                  defer: false, // optional, default to false
                  appendTo: 'head', // optional, default to "head", can be "head" or "body",
                  nonce: undefined // optional, default undefined
                }}
              >
                <Row className="justify-content-md-center " >
                  <Col lg={5} md={8} sm={12} xs={12} >
                    <Card className="login-form">
                      <Card.Body style={{ padding: 0 }}>
                        <div className="text-center">
                          <img src={logo} alt={web_title} className="img-fluid mb-2" style={{ maxHeight: 180 }} />
                        </div>

                        {(system.configuration && system.configuration.maintenance) ? (

                          <Alert variant="warning">
                            <div dangerouslySetInnerHTML={{ __html: system.configuration.message ? system.configuration.message.maintenance || 'ปิดปรับปรุงระบบ' : 'ปิดปรับปรุงระบบ' }}></div>
                          </Alert>
                        ) : (
                          <>



                            <div className="text-center">
                              <h2>สมัครสมาชิก</h2>

                            </div>
                            <Intro intro={intro} />

                            <Form onSubmit={this.submitForm} className="mt-4">

                              <Form.Group controlId="register_phone">
                                <Form.Label>{useTrueWallet ? 'เบอร์ True Wallet' : 'เบอร์โทรศัพท์มือถือ'}</Form.Label>
                                <InputGroup size="lg">
                                  <InputGroup.Prepend>
                                    <InputGroup.Text className="input-group-custom">
                                      <FontAwesomeIcon icon={faMobileAlt} />
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control className="input-with-icon" type="text" placeholder="กรุณากรอก เบอร์โทรศัพท์มือถือ 10 หลัก" pattern="^[0-9]+$" value={formdata?.phone} title="กรุณากรอก เบอร์โทรศัพท์มือถือ 10 หลัก" minLength={10} maxLength={10} required onChange={this.changeValue} disabled={loading} />
                                </InputGroup>
                              </Form.Group>

                              <div className="mb-4" style={{ display: isBank ? '' : 'none' }}>
                                <Form.Check custom size="lg" type="switch" id="selectUseTruewallet" label="ใช้ Truewallet" value="y" defaultChecked={!isBank} onChange={this.selectUseTruewallet} disabled={loading} />
                              </div>

                              {useTrueWallet &&
                                <>

                                  <Form.Group controlId="register_twid">
                                    <Form.Label>True Wallet ID (ถ้ามี)</Form.Label>
                                    <InputGroup size="lg">
                                      <InputGroup.Prepend>
                                        <InputGroup.Text className="input-group-custom">
                                          <FontAwesomeIcon icon={faMoneyCheck} />
                                        </InputGroup.Text>
                                      </InputGroup.Prepend>
                                      <Form.Control className="input-with-icon" type="text" placeholder={'กรุณากรอก วอเลท ไอดี'} minLength={3} maxLength={20} value={formdata?.twid} onChange={this.changeValue} />
                                    </InputGroup>
                                  </Form.Group>
                                </>
                              }

                              {isBank &&
                                <>
                                  <hr />
                                  <div className="mb-2">
                                    <Form.Check custom size="lg" type="switch" id="selectUseBank" label="ใช้บัญชีธนาคาร" value="y" onChange={this.selectUseBank} disabled={loading} />
                                  </div>

                                  {useBank &&
                                    <>
                                      <Form.Group controlId="register_bank" size="lg">
                                        <Form.Label>ธนาคาร</Form.Label>
                                        <Form.Control as="select" size="lg" value={formdata?.bank} onChange={this.changeValue} title="กรุณาเลือกธนาคาร">
                                          {banks.map((bank, i) => <option key={i} value={bank.id}>{bank.name}</option>)}
                                        </Form.Control>
                                      </Form.Group>


                                      <Form.Group controlId="register_bank_number">
                                        <Form.Label>เลขบัญชีธนาคาร</Form.Label>

                                        <InputGroup size="lg">
                                          <InputGroup.Prepend>
                                            <InputGroup.Text className="input-group-custom">
                                              <FontAwesomeIcon icon={faMoneyCheck} />
                                            </InputGroup.Text>
                                          </InputGroup.Prepend>
                                          <Form.Control className="input-with-icon" type="text" placeholder={'กรุณากรอก เลขบัญชีธนาคาร'} title={'กรุณากรอก เลขบัญชีธนาคาร'} pattern="^[0-9]+$" minLength={10} maxLength={20} required={true} value={formdata?.bank_number} onChange={this.changeValue} />
                                        </InputGroup>

                                      </Form.Group>
                                    </>
                                  }

                                </>
                              }


                              {!showFillName &&
                                <>
                                  {loading ? (
                                    <div className="text-center">
                                      <FontAwesomeIcon icon={faSpinner} size="3x" spin className="mb-1" /> <br />
                                      <div className="mb-2">กรุณารอสักครู่...</div>

                                      {isDelayCheckTruewallet && <h4>{delayCheckTruewallet}</h4>}
                                    </div>
                                  ) : (
                                    <Button variant="primary" type="button" block size="lg" className="btn-gold mt-4" disabled={!this.allowCheckTruewalletInfo()} onClick={() => { this.setState({ retryCheckTruewallet: 0 }, () => this.checkTruewalletInfo()) }}>ถัดไป</Button>
                                  )}
                                </>
                              }


                              {showFillName &&
                                <>
                                  <Form.Group controlId="register_firstname">
                                    <Form.Label>ชื่อจริง</Form.Label>
                                    <InputGroup size="lg">
                                      <InputGroup.Prepend>
                                        <InputGroup.Text className="input-group-custom">
                                          <FontAwesomeIcon icon={faSignature} />
                                        </InputGroup.Text>
                                      </InputGroup.Prepend>
                                      <Form.Control className="input-with-icon" type="text" placeholder="กรุณากรอก ชื่อจริงภาษาไทย" pattern="^[ก-๏\s]+$" title="กรุณากรอกชื่อ ภาษาไทย" value={formdata?.firstname} readOnly={!allowFillName} required={allowFillName} onChange={this.changeValue} />
                                    </InputGroup>
                                  </Form.Group>


                                  <Form.Group controlId="register_lastname">
                                    <Form.Label>นามสกุล</Form.Label>
                                    <InputGroup size="lg">
                                      <InputGroup.Prepend>
                                        <InputGroup.Text className="input-group-custom">
                                          <FontAwesomeIcon icon={faSignature} />
                                        </InputGroup.Text>
                                      </InputGroup.Prepend>

                                      <Form.Control className="input-with-icon" type="text" placeholder="กรุณากรอก นามสกุลภาษาไทย" pattern="^[ก-๏\s]+$" title="กรุณากรอกนามสกุล ภาษาไทย" value={formdata?.lastname} readOnly={!allowFillName} required={allowFillName} onChange={this.changeValue} />
                                    </InputGroup>
                                  </Form.Group>



                                  <Form.Group controlId="register_channel">
                                    <Form.Label>โปรโมชั่น</Form.Label>
                                    <Form.Control as="select" size="lg" onChange={this.selectPromotion} title="กรุณาเลือกช่องทางที่คุณมาสมัคร">
                                      <option value="">ไม่รับโปรโมชั่น</option>
                                      {channels.map((channel, i) => <option key={i} value={channel.code}>{channel.title}</option>)}
                                    </Form.Control>
                                  </Form.Group>

                                  {(promotion && promotion.id) &&
                                    <>

                                      {promotion.thumbnail && <img src={promotion.thumbnail} className="img-fluid" alt="" />}

                                      {promotion.description &&
                                        <Alert variant="info">{promotion.description}</Alert>
                                      }

                                    </>
                                  }



                                  {loading ? (
                                    <div className="text-center">
                                      <FontAwesomeIcon icon={faSpinner} size="3x" spin className="mb-2" /> <br />
                                      <span >กรุณารอสักครู่...</span>
                                      <br />
                                    </div>
                                  ) : (
                                    <>

                                      {!CLOSE_REGISTER &&
                                        <Button variant="primary" type="submit" block size="lg" className="btn-gold mb-4" >
                                          สมัครสมาชิก
                                        </Button>
                                      }


                                    </>
                                  )}

                                </>
                              }
                            </Form>


                            <hr className="gold" />
                            <div className="row text-center mt-4">
                              <div className="col-sm-6 text-center mt-4">
                                <Button block variant="success" size="lg" onClick={() => { this.setState({ redirectTarget: '/' }) }}>คลิกที่นี่เพื่อเข้าสู่ระบบ</Button>
                              </div>
                              <div className="col-sm-6 text-center mt-4">
                                <Button block variant="info" size="lg" onClick={() => { this.setState({ redirectTarget: '/forgetpassword' }) }}>ลืมรหัสผ่าน</Button>
                              </div>
                            </div>
                          </>
                        )}

                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </GoogleReCaptchaProvider>
            </>

          }


          {system && system.configuration.line && <Line id={system.configuration.line} />}

        </Layout>

        <Modal
          show={showModalSuccess}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={this.hideModal}
        >
          <Modal.Body>
            <h4 className="text-success">สมัครสมาชิกเรียบร้อยแล้ว</h4>
            <p>
              ระบบได้ส่ง <span className="text-info">ชื่อผู้ใช้ระบบ</span> และ <span className="text-info">รหัสผ่าน</span> ไปยังเบอร์มือถือ <span className="text-info">{this.state.phone || ''}</span> ทาง SMS เรียบร้อยแล้ว
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={this.hideModal}>ปิด</Button>
          </Modal.Footer>
        </Modal>

        <GA />

        {this.state.login_success && <Redirect to='/' />}
        {this.state.finish && <Redirect to='/signin' />}
        {redirectTarget && <Redirect to={redirectTarget} />}
      </>
    );
  }
}


export default Register;